var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-6"},[_c('div',{staticClass:"grid grid-cols-1 gap-6 lg:grid-cols-2 justify-items-center"},[_c('div',{staticClass:"w-full bg-white rounded-xl p-4 space-y-4"},[_vm._m(0),_c('div',{staticClass:"grid justify-between grid-cols-2 gap-6"},[_c('ColorCard',{attrs:{"icon":_vm.icon1,"title":"Crédit accordé","amount":this.element.creditDetails.creditAmount,"activeColor":"rgba(246, 249, 254, 1) ","shadowColor":"rgba(47, 128, 237, 0.2)","circleColor":"rgba(114, 169, 243, 1)"}}),_c('ColorCard',{attrs:{"icon":_vm.icon2,"title":"Mont. à rembourser en nominal","amount":this.element.creditDetails.creditAmount,"activeColor":"rgba(254, 246, 246, 1) ","shadowColor":"rgba(241, 28, 51, 0.2)","circleColor":"rgba(241, 141, 141, 1)"}}),_c('ColorCard',{attrs:{"icon":_vm.icon3,"title":"Mont. à rembourser en intérêt","amount":this.element.creditDetails.interestRate,"activeColor":"rgba(251, 247, 253, 1) ","shadowColor":"rgba(187, 107, 217, 0.4)","circleColor":"rgba(201, 133, 228, 1)"}}),_c('ColorCard',{attrs:{"icon":_vm.icon4,"title":"Montant total à rembourser","amount":this.element.creditDetails.creditAmount +
              this.element.creditDetails.interestRate,"activeColor":"rgba(254, 252, 251, 1) ","shadowColor":"rgba(207, 77, 3, 0.2)","circleColor":"rgba(248, 159, 54, 1)"}}),_c('ColorCard',{attrs:{"icon":_vm.icon5,"title":"Montant de l’échéance","amount":_vm.element.creditDetails.echeanceAmount,"activeColor":"rgba(245, 254, 250, 1) ","shadowColor":"rgba(39, 127, 24, 0.2)","circleColor":"rgba(115, 190, 114, 1)"}}),_c('ColorCard',{attrs:{"icon":_vm.icon6,"title":"Montant  total dû","amount":this.element.creditDetails.amountDue,"activeColor":"rgba(245, 246, 254, 1) ","shadowColor":"rgba(86, 102, 247, 0.2)","circleColor":"rgba(86, 102, 247, 1)"}})],1)]),_c('div',{staticClass:"flex-grow w-full"},[_c('ToogleCard',{attrs:{"title":"Bénéficiaire","greyTable":_vm.greyItem,"image":false,"greyCard":true,"headTable":_vm.HeadItem,"showButon":true,"headView":true,"bodyTable":_vm.BodyItem,"firstCardItem":_vm.firstCardItem,"subtitle":"Autres membres du groupe"},scopedSlots:_vm._u([{key:"buttonAction",fn:function(ref){
              var id = ref.id;
return [_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showFichegroup(id)}}},[_vm._v("Voir fiche client")])]}}])})],1)]),_c('div',{staticClass:"py-4"},[_c('div',{staticClass:"py-4"},[_c('div',{},[_c('div',{staticClass:"bg-white rounded-xl p-5"},[_c('span',{staticClass:"font-bold text-xl"},[_vm._v("Echéances")]),_c('div',{},[_c('div',{staticClass:"py-4 overflow-x-auto"},[_c('table',{staticClass:"w-full text-left w-full border-collapse"},[_vm._m(1),_c('tbody',{staticClass:"border-b text-xs truncate border-grey-light"},[(_vm.element.deadlines && _vm.element.deadlines.length <= 0)?_c('tr',[_c('td',{staticClass:"px-6 py-4 align-middle",attrs:{"colspan":_vm.columns.length}},[_vm._t("no-data",function(){return [_c('div',{staticClass:"text-xs text-center"},[_vm._v("Aucune donnée")])]})],2)]):_vm._e(),_vm._l((_vm.element.deadlineAll),function(credit,i){return _c('tr',{key:i,staticClass:"border-b w-full border-b"},[_c('td',{staticClass:"font-bold"},[_vm._v(_vm._s(i + 1))]),_c('td',{},[_vm._v(_vm._s(_vm.convertDate(credit.deadline.date)))]),_c('td',{},[_vm._v(_vm._s(credit.deadline.dueAmount))]),_c('td',{},[_vm._v(_vm._s(credit.deadline.paidAmount))]),_c('td',{},[_vm._v(" "+_vm._s(credit.lastPayment ? credit.lastPayment.payerPhone : '--')+" ")]),_c('td',{},[_vm._v(" "+_vm._s(credit.lastPayment ? _vm.convertDate(credit.lastPayment.createdAt) : '--')+" ")]),_c('td',{},[_c('div',{staticClass:"\n\n                          bg-yellow-200\n                          py-2\n                          px-2\n                          space-x-2\n                          items-center\n                          flex\n                          justify-start\n                          rounded-xl\n                        ",class:{
                          'bg-green-100': ("" + (credit.deadline.state)) == 'paid'
                        },staticStyle:{"width":"fit-content"}},[_c('div',{staticClass:"bg-yellow-600 w-2 h-2 rounded-full",class:{
                            'bg-secondary':
                              ("" + (credit.deadline.state)) == 'paid'
                          }}),_c('div',{staticClass:"font-bold text-yellow-600",class:{
                            'text-green-500':
                              ("" + (credit.deadline.state)) == 'paid'
                          }},[_vm._v(" "+_vm._s(credit.deadline.state == 'paid' ? 'Remboursé' : 'Non remboursé')+" ")])])])])})],2)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-b-2 p-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Montant")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"\n                    border-t border-grey-light\n                    text-xs\n                    truncate\n                    text-gray-900\n                  "},[_c('tr',[_c('th',{},[_vm._v("N°")]),_c('th',{},[_vm._v("Date échéance")]),_c('th',{},[_vm._v("Montant dû")]),_c('th',{},[_vm._v("Montant remboursé")]),_c('th',{},[_vm._v("Payé par")]),_c('th',{},[_vm._v("Date du paiement")]),_c('th',{},[_vm._v("Statut")])])])}]

export { render, staticRenderFns }