<template>
  <div class=" bg-white border-t p-4">
    <div class="flex ">
      <div class="bg-white  flex space-x-3  px-6">
        <div class="w-12 h-12 rounded-full flex-shrink-0 bg-blue-ink"></div>
        <div>
          <span class="font-bold text-xs truncate">
            {{
              getOneBeneficiaries.beneficiary
                ? getOneBeneficiaries.beneficiary.name
                : '---'
            }} </span
          ><br />
          <span class="truncate text-xs"
            >{{ getOneBeneficiaries.beneficiary.phone }}
          </span>
          <span
            class="truncate text-xs"
            v-if="getOneBeneficiaries.otmInfos.provider == 'mtn-benin'"
          >
            (MTN) - Profil Microcrédit</span
          >
        </div>
      </div>
      <div class=" flex space-x-3  border-l px-6 border-gray-600">
        <div>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25" cy="25" r="25" fill="#287D3C" />
            <path
              d="M21.9223 13.5198C22.1044 13.4368 22.3018 13.3928 22.5019 13.3905C26.3334 13.4384 26.4483 13.2804 26.9752 13.5438L27.071 13.5869C27.3825 12.7718 27.7572 11.9824 28.1917 11.2257C28.241 11.1437 28.2646 11.0489 28.2595 10.9534C28.2544 10.8579 28.2208 10.7662 28.1631 10.6899C28.1054 10.6137 28.0262 10.5565 27.9357 10.5256C27.8452 10.4948 27.7475 10.4917 27.6553 10.5169C26.3909 10.7851 26.0796 11.0341 25.5479 10.8377L25.2222 10.718C24.7904 10.5635 24.3203 10.5517 23.8812 10.6845L23.2729 10.8713C22.794 11.0245 22.5354 10.8138 21.18 10.5169C21.0863 10.4994 20.9895 10.5103 20.9021 10.5481C20.8146 10.5859 20.7404 10.649 20.689 10.7292C20.6376 10.8094 20.6113 10.9032 20.6134 10.9984C20.6155 11.0937 20.6461 11.1862 20.701 11.264C21.2297 11.9435 21.6423 12.7057 21.9223 13.5198Z"
              fill="white"
            />
            <path
              d="M23.9366 19.6168H23.7977C23.5437 19.6168 23.3 19.7177 23.1204 19.8974C22.9408 20.077 22.8398 20.3207 22.8398 20.5747C22.8398 20.8288 22.9408 21.0724 23.1204 21.252C23.3 21.4317 23.5437 21.5326 23.7977 21.5326H23.9366V19.6168Z"
              fill="white"
            />
            <path
              d="M25.9913 23.4484C25.9913 23.1943 25.8904 22.9507 25.7108 22.771C25.5311 22.5914 25.2875 22.4905 25.0334 22.4905H24.8945V24.4063H25.0334C25.2875 24.4063 25.5311 24.3053 25.7108 24.1257C25.8904 23.9461 25.9913 23.7024 25.9913 23.4484Z"
              fill="white"
            />
            <path
              d="M21.71 28.2713C21.8121 28.2481 21.9166 28.2368 22.0213 28.2378C22.0835 28.233 22.1459 28.233 22.2081 28.2378L28.4679 29.0712C29.0531 29.151 29.5914 29.4351 29.9875 29.8733C30.3836 30.3115 30.6121 30.8755 30.6327 31.4659C31.1647 31.2928 31.6194 30.9389 31.9177 30.4656C32.216 29.9923 32.3392 29.4294 32.2659 28.8748L30.9584 19.0708C30.8488 18.2572 30.5419 17.4827 30.0644 16.8149C29.5869 16.147 28.9533 15.6061 28.2188 15.2393L26.5473 14.4059C26.481 14.3701 26.4072 14.3504 26.3318 14.3484H22.5002C22.4253 14.3489 22.3514 14.3669 22.2847 14.4011L20.6132 15.2345C19.8787 15.6013 19.2451 16.1422 18.7676 16.8101C18.2901 17.4779 17.9832 18.2524 17.8737 19.066C16.5087 29.3968 16.547 28.8796 16.547 29.1957H17.5528L21.71 28.2713ZM23.7982 22.4905C23.2901 22.4905 22.8028 22.2886 22.4435 21.9294C22.0842 21.5701 21.8824 21.0828 21.8824 20.5747C21.8824 20.0666 22.0842 19.5793 22.4435 19.2201C22.8028 18.8608 23.2901 18.6589 23.7982 18.6589H23.9371V17.4951H24.895V18.6589H25.0339C25.5419 18.6589 26.0292 18.8608 26.3885 19.2201C26.7478 19.5793 26.9496 20.0666 26.9496 20.5747H25.9917C25.9917 20.3207 25.8908 20.077 25.7112 19.8974C25.5315 19.7177 25.2879 19.6168 25.0339 19.6168H24.895V21.5326H25.0339C25.5419 21.5326 26.0292 21.7344 26.3885 22.0937C26.7478 22.453 26.9496 22.9403 26.9496 23.4484C26.9496 23.9565 26.7478 24.4437 26.3885 24.803C26.0292 25.1623 25.5419 25.3641 25.0339 25.3641H24.895V26.528H23.9371V25.3641H23.7982C23.2901 25.3641 22.8028 25.1623 22.4435 24.803C22.0842 24.4437 21.8824 23.9565 21.8824 23.4484H22.8403C22.8403 23.7024 22.9412 23.9461 23.1209 24.1257C23.3005 24.3053 23.5441 24.4063 23.7982 24.4063H23.9371V22.4905H23.7982Z"
              fill="white"
            />
            <path
              d="M13.8813 36.3798H16.6783L22.349 38.2955C22.5597 38.3626 22.1143 38.377 30.7257 36.8587C30.8131 36.8461 30.8948 36.8077 30.9604 36.7486L34.6914 33.4343C34.9563 33.1986 35.12 32.8695 35.148 32.516C35.1761 32.1625 35.0664 31.8118 34.8419 31.5372C34.6175 31.2626 34.2956 31.0855 33.9435 31.0427C33.5914 30.9999 33.2365 31.0949 32.9528 31.3078L29.969 33.5396L24.4947 34.464L23.3117 33.8749L23.6757 33.5109L28.2975 33.123C28.679 33.0881 29.0335 32.9113 29.2907 32.6274C29.548 32.3436 29.6892 31.9734 29.6864 31.5903C29.6864 31.2153 29.5506 30.8529 29.304 30.5703C29.0575 30.2876 28.7169 30.1039 28.3454 30.0529L22.0856 29.1956C22.0301 29.1858 21.9734 29.1858 21.9179 29.1956L17.6601 30.1535H13.8813C13.7543 30.1535 13.6324 30.204 13.5426 30.2938C13.4528 30.3836 13.4023 30.5054 13.4023 30.6325V35.9008C13.4023 36.0278 13.4528 36.1497 13.5426 36.2395C13.6324 36.3293 13.7543 36.3798 13.8813 36.3798Z"
              fill="white"
            />
          </svg>
        </div>
        <div>
          <span class="font-bold"> Crédit en cours </span><br />

          <div v-if="getOneBeneficiaries.creditInProgress != false">
            <span class="truncate text-xs"
              >{{
                getOneBeneficiaries.creditInProgress
                  ? getOneBeneficiaries.creditInProgress.creditAmount
                  : '---'
              }}
              Fcfa chez
            </span>
            <span class="truncate text-xs"
              >{{ getOneBeneficiaries.creditInProgress.creditOwner }}
            </span>
          </div>
          <span v-else> Aucun crédit </span>
        </div>
      </div>
      <div class="flex border-l  px-6  space-x-3 border-gray-600">
        <div>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25" cy="25" r="25" fill="#DA1414" />
            <path
              d="M26.9622 9.47263C26.6293 9.16681 26.1576 9 25.6026 9C25.0754 9 24.576 9.16681 24.2708 9.47263C23.8823 9.83406 23.2996 10.0565 22.6615 10.0565C22.0233 10.0565 21.4406 9.83406 21.0521 9.47263C20.7747 9.16681 20.2752 9 19.748 9C19.2208 9 18.7214 9.16681 18.4162 9.47263C18.111 9.75065 17.667 9.91746 17.1953 10.0009L20.3585 14.477C21.0799 14.2546 21.8845 14.1156 22.7447 14.1156C23.5494 14.1156 24.2985 14.2546 25.0477 14.477L28.1554 9.97307C27.6837 9.91746 27.2675 9.75065 26.9622 9.47263Z"
              fill="white"
            />
            <path
              d="M21.9653 20.7324H22.1318V22.9566H21.9653C21.3549 22.9566 20.8555 22.4561 20.8555 21.8445C20.8555 21.2329 21.3549 20.7324 21.9653 20.7324Z"
              fill="white"
            />
            <path
              d="M23.2422 24.0964H23.4087C24.0191 24.0964 24.5185 24.5969 24.5185 25.2085C24.5185 25.8202 24.0191 26.3206 23.4087 26.3206H23.2422V24.0964Z"
              fill="white"
            />
            <path
              d="M22.7148 15.3389C17.5261 15.3389 15.0011 20.649 13.3086 27.4049C12.0322 32.5483 14.6682 35.2728 22.1043 35.4118C22.0211 34.9114 21.9656 34.3832 21.9656 33.8271C21.9656 31.3528 22.9645 29.1564 24.5738 27.5439C26.1831 25.9314 28.4029 24.9305 30.8446 24.9305C31.0943 24.9305 31.3163 24.9305 31.5383 24.9583C30.1232 19.537 27.2375 15.3389 22.7148 15.3389ZM23.4084 22.9566C24.6293 22.9566 25.6282 23.9575 25.6282 25.1807C25.6282 26.404 24.6293 27.4049 23.4084 27.4049H23.242V28.7672H22.1321V27.4049H21.9656C20.7447 27.4049 19.7458 26.404 19.7458 25.1807H20.8557C20.8557 25.7924 21.3552 26.2928 21.9656 26.2928H22.1321V24.0687H21.9656C20.7447 24.0687 19.7458 23.0678 19.7458 21.8445C19.7458 20.6212 20.7447 19.6204 21.9656 19.6204H22.1321V18.2581H23.242V19.6204H23.4084C24.6293 19.6204 25.6282 20.6212 25.6282 21.8445H24.5183C24.5183 21.2329 24.0189 20.7324 23.4084 20.7324H23.242V22.9566H23.4084Z"
              fill="white"
            />
            <path
              d="M35.9239 28.7671C35.1192 27.9609 34.1481 27.377 33.066 27.0156C32.7053 26.9044 32.3445 26.821 31.9838 26.7376C31.6231 26.682 31.2347 26.6542 30.874 26.6542C28.9039 26.6542 27.1004 27.4604 25.7963 28.7671C24.4922 30.0738 23.6875 31.8531 23.6875 33.8271C23.6875 34.3553 23.743 34.8835 23.854 35.384C23.8817 35.523 23.9095 35.662 23.965 35.801C24.2979 36.9965 24.9361 38.053 25.7685 38.887C27.0726 40.1937 28.8484 41 30.8462 41C32.8162 41 34.6198 40.1937 35.8962 38.887C37.2003 37.5803 37.9772 35.801 37.9772 33.8271C38.0049 31.8531 37.228 30.046 35.9239 28.7671ZM34.5643 36.3848C35.3135 37.1355 34.1481 38.3032 33.3989 37.5525C33.1492 37.2745 32.8717 37.0243 32.622 36.7741C32.0393 36.1902 31.4566 35.6064 30.874 35.0225L28.349 37.5525C27.5998 38.3032 26.4344 37.1355 27.1836 36.3848C27.4333 36.1068 27.7108 35.8566 27.9605 35.6064C28.3767 35.1894 28.8207 34.7445 29.2369 34.3275C29.4034 34.1607 29.5421 34.0217 29.7086 33.8549C28.8762 33.0208 28.016 32.159 27.1836 31.3249C26.4344 30.5742 27.5998 29.4066 28.349 30.1572C28.5987 30.4352 28.8762 30.6855 29.1259 30.9357L30.874 32.6872C31.3734 32.1868 31.8451 31.6863 32.3445 31.2137C32.7053 30.8523 33.0382 30.5186 33.3989 30.1572C34.1481 29.4066 35.3135 30.5742 34.5643 31.3249L32.0393 33.8549C32.8717 34.6889 33.7041 35.523 34.5643 36.3848Z"
              fill="white"
            />
          </svg>
        </div>
        <div>
          <span class="font-bold ">Impayé</span> <br />
          <div v-if="getOneBeneficiaries.creditInProgress != false">
            <span class="truncate text-xs"
              >{{ getOneBeneficiaries.creditInProgress.amountDue }} Fcfa chez
            </span>
            <span class="truncate text-xs">{{
              getOneBeneficiaries.creditInProgress.creditOwner
            }}</span>
          </div>
          <span v-else> Aucun crédit</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    beneficiary: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('ficheClient', ['getOneBeneficiaries'])
  },
  methods: {
    ...mapActions('ficheClient', ['detailsBeneficiaries'])
  }
}
</script>

<style></style>
