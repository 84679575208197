<template>
  <div class="p-6">
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 justify-items-center">
      <div class="w-full bg-white rounded-xl p-4 space-y-4">
        <div class="border-b-2 p-2">
          <span class="font-bold">Montant</span>
        </div>
        <div class="grid justify-between grid-cols-2 gap-6">
          <ColorCard
            :icon="icon1"
            title="Crédit accordé"
            :amount="this.element.creditDetails.creditAmount"
            activeColor="rgba(246, 249, 254, 1) "
            shadowColor="rgba(47, 128, 237, 0.2)"
            circleColor="rgba(114, 169, 243, 1)"
          />
          <ColorCard
            :icon="icon2"
            title="Mont. à rembourser en nominal"
            :amount="this.element.creditDetails.creditAmount"
            activeColor="rgba(254, 246, 246, 1) "
            shadowColor="rgba(241, 28, 51, 0.2)"
            circleColor="rgba(241, 141, 141, 1)"
          />
          <ColorCard
            :icon="icon3"
            title="Mont. à rembourser en intérêt"
            :amount="this.element.creditDetails.interestRate"
            activeColor="rgba(251, 247, 253, 1) "
            shadowColor="rgba(187, 107, 217, 0.4)"
            circleColor="rgba(201, 133, 228, 1)"
          />
          <ColorCard
            :icon="icon4"
            title="Montant total à rembourser"
            :amount="
              this.element.creditDetails.creditAmount +
                this.element.creditDetails.interestRate
            "
            activeColor="rgba(254, 252, 251, 1) "
            shadowColor="rgba(207, 77, 3, 0.2)"
            circleColor="rgba(248, 159, 54, 1)"
          />
          <ColorCard
            :icon="icon5"
            title="Montant de l’échéance"
            :amount="element.creditDetails.echeanceAmount"
            activeColor="rgba(245, 254, 250, 1) "
            shadowColor="rgba(39, 127, 24, 0.2)"
            circleColor="rgba(115, 190, 114, 1)"
          />
          <ColorCard
            :icon="icon6"
            title="Montant  total dû"
            :amount="this.element.creditDetails.amountDue"
            activeColor="rgba(245, 246, 254, 1) "
            shadowColor="rgba(86, 102, 247, 0.2)"
            circleColor="rgba(86, 102, 247, 1)"
          />
        </div>
      </div>
      <div class="flex-grow w-full">
        <ToogleCard
          title="Bénéficiaire"
          :greyTable="greyItem"
          :image="false"
          :greyCard="true"
          :headTable="HeadItem"
          :showButon="true"
          :headView="true"
          :bodyTable="BodyItem"
          :firstCardItem="firstCardItem"
          subtitle="Autres membres du groupe"
        >
          <template #buttonAction="{ id }">
            <a @click="showFichegroup(id)" class="cursor-pointer"
              >Voir fiche client</a
            >
          </template>
        </ToogleCard>
      </div>
    </div>
    <div class="py-4">
      <div class="py-4">
        <div class="">
          <div class="bg-white rounded-xl p-5">
            <span class="font-bold text-xl">Echéances</span>
            <div class="">
              <div class="py-4 overflow-x-auto">
                <table class="w-full text-left w-full border-collapse">
                  <thead
                    class="
                      border-t border-grey-light
                      text-xs
                      truncate
                      text-gray-900
                    "
                  >
                    <tr>
                      <th class="">N°</th>
                      <th class="">Date échéance</th>
                      <th class="">Montant dû</th>
                      <th class="">Montant remboursé</th>
                      <th class="">Payé par</th>
                      <th class="">Date du paiement</th>
                      <th class="">Statut</th>
                    </tr>
                  </thead>

                  <tbody class="border-b text-xs truncate border-grey-light">
                    <tr
                      v-if="element.deadlines && element.deadlines.length <= 0"
                    >
                      <td
                        :colspan="columns.length"
                        class="px-6 py-4 align-middle"
                      >
                        <slot name="no-data">
                          <div class="text-xs text-center">Aucune donnée</div>
                        </slot>
                      </td>
                    </tr>

                    <tr
                      class="border-b w-full border-b"
                      v-for="(credit, i) in element.deadlineAll"
                      :key="i"
                    >
                      <td class="font-bold">{{ i + 1 }}</td>
                      <td class="">{{ convertDate(credit.deadline.date) }}</td>
                      <td class="">{{ credit.deadline.dueAmount }}</td>
                      <td class="">{{ credit.deadline.paidAmount }}</td>
                      <td class="">
                        {{
                          credit.lastPayment
                            ? credit.lastPayment.payerPhone
                            : '--'
                        }}
                      </td>
                      <td class="">
                        {{
                          credit.lastPayment
                            ? convertDate(credit.lastPayment.createdAt)
                            : '--'
                        }}
                      </td>
                      <td class="">
                        <div
                          style="width:fit-content"
                          class="

                            bg-yellow-200
                            py-2
                            px-2
                            space-x-2
                            items-center
                            flex
                            justify-start
                            rounded-xl
                          "
                          v-bind:class="{
                            'bg-green-100': `${credit.deadline.state}` == 'paid'
                          }"
                        >
                          <div
                            class="bg-yellow-600 w-2 h-2 rounded-full"
                            v-bind:class="{
                              'bg-secondary':
                                `${credit.deadline.state}` == 'paid'
                            }"
                          ></div>
                          <div
                            class="font-bold text-yellow-600"
                            v-bind:class="{
                              'text-green-500':
                                `${credit.deadline.state}` == 'paid'
                            }"
                          >
                            {{
                              credit.deadline.state == 'paid'
                                ? 'Remboursé'
                                : 'Non remboursé'
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorCard from '../../../../components/ColorCard.vue'
import ToogleCard from '../../../../components/ToogleCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ColorCard,
    ToogleCard
  },
  props: {
    element: {
      type: {}
    }
  },
  computed: {
    ...mapGetters('ficheClient', [
      'getOneBeneficiaries',
      'getCurrentBeneficiaries',
      'getBeneficiaries',
      'getAll'
    ]),
    getElement() {
      return this.element
    },
    greyItem() {
      if (this.getOneBeneficiaries)
        return [
          { title: 'Nom du groupe', value: this.element.group.name },
          {
            title: 'Poste occupé',
            value:
              this.element.group.representativeId ==
              this.getOneBeneficiaries.beneficiary.id
                ? 'Président'
                : 'Membre'
          }
        ]
      return []
    },
    BodyItem() {
      if (this.getOneBeneficiaries) {
        const tab = []
        for (const i of this.element.group.members) {
          tab.push({
            value: i.name,
            values: i.phone,
            id: i.id
          })
        }
        return tab
      }
      return []
    },

    firstCardItem() {
      if (this.getOneBeneficiaries)
        return [
          {
            title: 'Nom et prénoms',
            value: this.getOneBeneficiaries.beneficiary.name
          },
          {
            title: 'Numéro mobile',
            value: this.getOneBeneficiaries.beneficiary.phone
          },
          {
            title: 'Sexe',
            value:
              this.getOneBeneficiaries.beneficiary.gender == 'M'
                ? 'Masculin'
                : 'Féminin'
          },
          {
            title: 'Commune',
            value: this.getOneBeneficiaries.beneficiary.state
          },
          {
            title: 'Arrondissement',
            value: this.getOneBeneficiaries.beneficiary.district
          },
          { title: 'Ville', value: this.getOneBeneficiaries.beneficiary.town }
        ]
      return []
    }
  },
  data() {
    return {
      greenClass: 'text-green-200 bg-green-100 ',

      columns: [
        { label: 'N°', field: 'num' },
        { label: 'Date échéance', field: 'dateEche' },
        { label: 'Montant dû', field: 'amountDu' },
        { label: 'Montant remboursé', field: 'amountRemb' },
        {
          label: 'Payé par',
          field: 'payerPar'
        },

        { label: 'Date du paiement', field: 'datePaie' },
        { label: 'Statut', field: 'status' }
      ],

      amount: 50000,
      icon1: 'creditaccord',
      icon2: 'nominalamount',
      icon3: 'interestamount',
      icon4: 'totalamount',
      icon5: 'echeanceamount',
      icon6: 'totalamountdu',

      HeadItem: [
        { title: 'Nom et prénoms' },
        { title: 'Numéro de téléphone' },
        { title: 'Action' }
      ],
      EcheanceHead: [
        { title: 'N°' },
        { title: 'Date échéance' },
        { title: 'Montant dû' },
        { title: 'Montant remboursé' },
        { title: 'Payé par' },
        { title: 'Date du paiement' },
        { title: 'Status' }
      ]
    }
  },
  methods: {
    ...mapActions('ficheClient', [
      'detailsBeneficiaries',
      'showDetails',
      'allBeneficiaries'
    ]),
    showFichegroup(i) {
      console.log(this.getCurrentBeneficiaries, 'ici oh')
      console.log(i)
      const Current = this.getAll.find(element => element.beneficiary.id == i)
      console.log(Current, 'Current')
      this.showDetails(Current)
      console.log(this.getCurrentBeneficiaries, 'ici 2 oh')
      console.log(this.getAll, 'tous')
      this.detailsBeneficiaries(i)

      this.$router.push({
        name: 'DetailsFiche',
        params: { id: i }
      })
    },
    convertDate(data) {
      if (data == '') return
      const changeDate = new Date(data)

      return changeDate.toLocaleString('default').split(',')[0]
    }
  }
}
</script>

<style></style>
