<template>
  <div style="height: 320px" class="bg-white rounded-xl flex flex-col">
    <div class="bg-white flex-shrink-0 w-full p-4 pb-1 rounded-t-xl">
      <div class="flex border-b-2 py-2 space-x-10">
        <div class="w-2/5">
          <a
            v-on:click="toggle(0)"
            class="
              cursor-pointer
              font-bold
              border-b-3 border-transparent
              py-2
              px-4
              focus:text-blue-active focus:border-blue-active
            "
            v-bind:class="{
              'font-bold bg-white border-blue-active text-blue-active':
                actuel === 0
            }"
            >{{ title }}</a
          >
        </div>
        <div class="flex-grow-0 w-4/5">
          <a
            v-on:click="toggle(1)"
            class="
              cursor-pointer
              font-bold
              border-b-4 border-transparent
              py-2
              px-4
              focus:text-blue-active focus:border-blue-active
            "
            v-bind:class="{
              'font-bold bg-white  border-blue-active text-blue-active':
                actuel === 1
            }"
          >
            {{ subtitle }}
          </a>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col flex-grow bg-white rounded-b-xl"
      v-if="getActuel === 0"
    >
      <div class="flex rounded-b-xl p-4" v-if="firstCardItem.length != 0">
        <div class="rounded-full w-20 h-20" v-if="image">
          <img :src="link" alt="" class="w-full rounded-full" />
        </div>
        <div class="flex-grow">
          <div
            class="px-4 truncate"
            v-for="data in firstCardItem"
            :key="data.id"
          >
            <div class="flex space-x-5 py-2 border-b flex-grow">
              <div class="w-full">
                <v-text as="p" size="xs">{{ data.title }}</v-text>
              </div>

              <div class="w-full">
                <v-text as="p" mt="1" size="xs" class="" weight="semibold ">
                  {{ data.value }}
                </v-text>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
      <div class="flex h-full items-center justify-center -mt-3" v-else>
        <div class="text-xl text-center text-gray-700">
          Aucune donnée disponible
        </div>
      </div>
    </div>

    <div v-else class="bg-white px-4 py-2 rounded-b-xl">
      <div class="" v-if="greyCard">
        <div class="bg-gray-200 flex justify-between -mt-3 px-2 border-b-2" v>
          <div class="w-1/2" v-for="item in greyTable" :key="item.id">
            <div class="">
              <div class="flex items-baseline space-x-2 py-2">
                <div>
                  <v-text as="p" class="truncate" size="xs"
                    >{{ item.title }}:</v-text
                  >
                </div>

                <div class="truncate">
                  <v-text as="p" mt="1" size="xs" class="" weight="semibold">
                    {{ item.value }}
                  </v-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-2">
        <table class="text-left w-full border-collapse">
          <thead class="border-grey-light text-gray-900" v-if="headView">
            <tr>
              <th
                class="truncate text-xs font-semibold"
                v-for="item in headTable"
                :key="item.id"
              >
                {{ item.title }}
              </th>
            </tr>
          </thead>
          <tbody
            class="border-b border-grey-light"
            v-for="item in bodyTable"
            :key="item.id"
          >
            <tr class="hover:bg-grey-lighter">
              <td class="p-2 truncate text-xs">{{ item.value }}</td>
              <td class="font-semibold text-xs">
                {{ item.values ? item.values : 'Néant' }}
              </td>

              <td v-if="showButon" class="underline text-xs text-blue-active">
                <slot name="buttonAction" :id="item.id"> Voir details </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actuel: 0
    }
  },

  props: {
    link: String,
    title: String,
    subtitle: String,
    firstCardItem: Array,
    secondCardItem: Array,
    headTable: Array,
    bodyTable: Array,
    greyCard: Boolean,
    headView: Boolean,
    showButon: Boolean,
    image: Boolean,
    greyTable: Array
  },
  computed: {
    getActuel() {
      return this.actuel
    }
  },
  methods: {
    toggle(index) {
      this.actuel = index
      console.log(this.actuel)
      return this.actuel
    }
  }
}
</script>

<style></style>
