<template>
  <div class="">
    <FicheNav />

    <div v-if="error != ''" class="flex items-center flex-grow justify-center">
      <div
        class="space-x-8 flex bg-red-100 items-center border border-danger text-red-700 p-4 rounded  m-8"
      >
        <div class="block sm:inline text-danger">
          {{ error }}
        </div>
        <v-button
          block
          small
          color="secondary"
          class="text-center"
          @click="$router.go()"
        >
          Réessayer
        </v-button>
      </div>
    </div>

    <div v-else>
      <div v-if="getOneBeneficiaries">
        <a @click="goback()" class="cursor-pointer">
          <div
            class="bg-blue-bg flex justify-start items-center py-4 px-8 space-x-4"
          >
            <div>
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.92969 6.99999L8.50069 2.42899L7.00069 0.928986L0.929688 6.99999L7.00069 13.071L8.50069 11.571L3.92969 6.99999Z"
                  fill="black"
                />
              </svg>
            </div>

            <div class="flex font-medium space-x-1">
              <span>"Microcrédit Alafia" </span>
              <span>-</span>
              <span
                >Ligne de crédit
                {{
                  element.creditDetails.credit.creditLine
                    ? element.creditDetails.credit.creditLine.name
                    : '---'
                }}</span
              >
              <span>-</span>

              <span>Agence {{ element.creditDetails.credit.agency }}</span>
              <span>-</span>
              <span>
                {{
                  element.creditDetails.credit.creditLine
                    ? element.creditDetails.credit.organization.name
                    : '---'
                }}</span
              >
            </div>
          </div>
        </a>
        <InfoCredit :element="element" />
      </div>
      <div class="flex h-screen w-full items-center  justify-center" v-else>
        <div class="loader flex flex-col h-full w-full "></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfoCredit from '../ficheclient/infocredit.vue'
import FicheNav from '../../../../components/FicheNavBar.vue'
export default {
  data() {
    return {
      error: '',
      element: {
        type: Object
      }
    }
  },
  components: {
    FicheNav,
    InfoCredit
  },
  computed: {
    ...mapGetters('ficheClient', ['getOneBeneficiaries'])
  },
  methods: {
    ...mapActions('ficheClient', ['detailsBeneficiaries']),
    goback() {
      this.$router.back()
    }
  },

  async beforeMount() {
    const creditLineId = this.$route.params.creditLineId

    if (!this.getOneBeneficiaries) {
      try {
        await this.detailsBeneficiaries(this.$route.params.id)
      } catch (e) {
        this.error = e
      }
    }
    const index = this.getOneBeneficiaries.credits.findIndex(
      value => value.creditDetails.credit.creditLineId == creditLineId
    )
    if (index != -1) {
      this.element = this.getOneBeneficiaries.credits[index]
    }
  }
}
</script>

<style scoped>
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #149e36; /* green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
