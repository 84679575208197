<template>
  <div
    class="flex rounded-xl   text-blue-dark space-x-2   items-center flex-shrink-0  w-full p-2"
    v-bind:style="{
      'background-color': activeColor,
      'box-shadow': `1px 3px 1px 0px ${shadowColor}`
    }"
  >
    <div
      class=" flex-shrink-0 icon rounded-full w-10 h-10 flex items-center justify-center"
      v-bind:style="{ 'background-color': circleColor }"
    >
      <img :src="image" />
    </div>
    <div
      class=""
      style="white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;"
    >
      <span style="font-size:11px" class="truncate ">{{ title }}</span> <br />
      <span class="font-bold " style="font-size:15px">{{ amount }} Fcfa</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      image: ''
    }
  },
  props: {
    title: String,
    amount: Number,
    activeColor: String,
    icon: String,
    shadowColor: String,
    circleColor: String
  },
  created() {
    this.image = require(`../assets/icons/Dashboard/${this.icon}.svg`)
  }
}
</script>

<style></style>
